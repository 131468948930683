import type { ComponentProps, HTMLAttributes, ReactElement } from 'react'
import {
  Children,
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import { cn } from '~/utils/cn'

import { Input, INPUT_DEFAULT_SIZE } from './Input'
import { TextArea } from './TextArea'

type InputGroupProps = HTMLAttributes<HTMLLabelElement> & {
  children: React.ReactNode
}

type InputGroupChildren = {
  prefix: ReactElement<ComponentProps<typeof InputGroupAddon>> | null
  input:
    | ReactElement<ComponentProps<typeof Input>>
    | ReactElement<ComponentProps<typeof TextArea>>
    | null
  suffix: ReactElement<ComponentProps<typeof InputGroupAddon>> | null
}

const InputGroup = forwardRef<HTMLLabelElement, InputGroupProps>(
  ({ children, className, ...props }, ref) => {
    const [suffixWidth, setSuffixWidth] = useState<number>()
    const [prefixWidth, setPrefixWidth] = useState<number>()

    const childrenArray = Children.toArray(children)

    const { prefix, input, suffix } = childrenArray.reduce<InputGroupChildren>(
      (result, child) => {
        if (isValidElement(child)) {
          if (child.type === Input || child.type === TextArea) {
            result.input = child as InputGroupChildren['input']
          } else if (child.type === InputGroupAddon) {
            if (!result.input) {
              result.prefix = cloneElement(child, {
                ...child.props,
                onResize: (entry?: ResizeObserverEntry) => {
                  setPrefixWidth(entry?.borderBoxSize[0]?.inlineSize)
                },
              }) as InputGroupChildren['prefix']
            } else {
              result.suffix = cloneElement(child, {
                ...child.props,
                onResize: (entry?: ResizeObserverEntry) => {
                  setSuffixWidth(entry?.borderBoxSize[0]?.inlineSize)
                },
              }) as InputGroupChildren['suffix']
            }
          }
        }

        return result
      },
      { prefix: null, input: null, suffix: null }
    )

    const inputSize = isValidElement(input)
      ? input.props?.size || INPUT_DEFAULT_SIZE
      : INPUT_DEFAULT_SIZE
    const inputDisabled = isValidElement(input)
      ? input.props?.disabled || undefined
      : undefined

    return (
      <label
        ref={ref}
        className={cn(
          'group relative flex cursor-text items-center text-xs',
          className
        )}
        data-size={inputSize}
        data-disabled={inputDisabled}
        style={{
          '--input-pl': prefixWidth ? `${prefixWidth}px` : '',
          '--input-pr': suffixWidth ? `${suffixWidth}px` : '',
        }}
        {...props}
      >
        {prefix}
        {input}
        {suffix}
      </label>
    )
  }
)

InputGroup.displayName = 'InputGroup'

type InputGroupAddonProps = HTMLAttributes<HTMLDivElement> & {
  onResize?: (entry?: ResizeObserverEntry) => void
}

const InputGroupAddon = forwardRef<HTMLDivElement, InputGroupAddonProps>(
  ({ className, onResize, children, ...props }, forwardRef) => {
    const ref = useRef<HTMLDivElement>(null)
    useImperativeHandle(forwardRef, () => ref.current!, [])

    useEffect(() => {
      const observer = new ResizeObserver(([entry]) => onResize?.(entry))
      observer.observe(ref.current!)

      return () => observer.disconnect()
    }, [onResize])

    return (
      <div
        ref={ref}
        className={cn(
          'absolute top-1/2 -translate-y-1/2 text-foreground/64 first:left-0 first:pl-2 last:right-0 last:pr-2',
          'flex items-center justify-center',
          'group-data-[disabled]:pointer-events-none group-data-[disabled]:opacity-48 group-data-[size="lg"]:first:pl-3.5 group-data-[size="lg"]:last:pr-3.5',
          className
        )}
        {...props}
      >
        {children}
      </div>
    )
  }
)

InputGroupAddon.displayName = 'InputGroupAddon'

const InputGroupNamespace = Object.assign(InputGroup, {
  Addon: InputGroupAddon,
  Input,
  TextArea,
})

export { InputGroupNamespace as InputGroup }
