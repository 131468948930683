import { forwardRef } from 'react'

import { styles as inputStyles } from './Input'

const TextArea = forwardRef<
  HTMLTextAreaElement,
  React.InputHTMLAttributes<HTMLTextAreaElement>
>(({ className, ...props }, ref) => {
  return (
    <textarea
      ref={ref}
      className={inputStyles({ className: ['resize-none', className] })}
      {...props}
    />
  )
})
TextArea.displayName = 'TextArea'

export { TextArea }
